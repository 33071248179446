<script lang="ts" setup>
import { vIntersectionObserver } from '@vueuse/components'

export interface Props {
  locality?: string
  stine?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  locality: '',
  stine: true
})

function onIntersectionObserver([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
  if (isIntersecting) {
    trackScrollToSectionIfReady(target.attributes['data-section-name'].value)
  }
}

function trackScrollToSectionIfReady(sectionName: string) {
  if (readyToTrackScrollEvents.value) {
    trackEvent('scroll_to_section', sectionName)
  }
}

// Wenn man hier nicht wartet, werden alle Events beim Seitenaufbau getrackt
const readyToTrackScrollEvents = ref(false)

onMounted(() => {
  setTimeout(() => {
    readyToTrackScrollEvents.value = true
  }, 300)
})

</script>

<template lang="pug">
  section.py-12.px-6(class="bg-slate-700")
    .container.mx-auto
      ScrollMenu
      Hero(:cityName="props.locality" :stine="props.stine")

  section.py-24.px-6
    .container.mx-auto
      OfficialCooperation
  
  section.py-24.px-6.sc-vorteile-section(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="vorteile"
  )
    .container.mx-auto
      SectionIntro.text-center.max-w-3xl
        template(#section-name) Vorteile
        template(#section-title) Einfach, schnell, sicher
        template(#section-description)
          p Melden Sie Ihr Fahrzeug mithilfe unserer Plattform in wenigen Minuten ab, ohne zur Zulassungsstelle {{ locality ? `${locality} ` : '' }}zu gehen.

      .grid.grid-cols-1.gap-16.mt-12(class="md:grid-cols-2")
        AdvantageCard(
          title="100% Geld-zurück-Garantie"
          iconAlt="Icon: Münzen in einer Hand"
          iconSrc="/images/icons/coins-hand.svg"
        )
          p Sollte die Online Außerbetriebsetzung aus irgendeinem Grund nicht klappen, erstatten wir Ihnen umgehend den vollen Betrag zurück.

        AdvantageCard(
          title="Unser Service ist 24/7 verfügbar"
          iconAlt="Icon: Uhr mit Pfeil"
          iconSrc="/images/icons/clock-refresh.svg"
        )
          p Sie haben rund um die Uhr Zugang zu unserem Service. Egal, ob Sie morgens um 7 Uhr oder abends um 22 Uhr abmelden möchten, unser Service ist für Sie verfügbar.

        AdvantageCard(
          title="Abmeldung in 3 Minuten"
          iconAlt="Icon: Blitz mit Geschwindigkeits-Linien"
          iconSrc="/images/icons/zap-fast.svg"
        )
          p Endlich ist es so weit: Sie möchten Ihr Fahrzeug abmelden? Mit <i>Abmeldung.digital</i> geht das ganz einfach und bequem von zu Hause aus. In nur wenigen Minuten erledigen Sie alle erforderlichen Schritte und erhalten Ihre Abmeldung sofort per E-Mail.

        AdvantageCard(
          :title="`Gang zur Zulassungsstelle ${locality ? `${locality} ` : ''}komplett ersparen`"
          iconAlt="Icon: Gebäude"
          iconSrc="/images/icons/building-08.svg"
        )
          p Sie müssen nicht mehr in die Zulassungsstelle {{ locality ? `${locality} ` : '' }}fahren und dort in der Warteschlange stehen. Über <i>Abmeldung.digital</i> erhalten Sie sofort nach Eingabe der Pflichtfelder alle notwendigen Dokumente der Zulassungsstelle.

  section.py-24.px-6.sc-anleitung-section(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="anleitung"
  )
    .container.mx-auto
      SectionIntro.text-center.max-w-3xl
        template(#section-name) Anleitung
        template(#section-title) Kfz online abmelden: So funktioniert's
        template(#section-description)
          p Sie können Ihr Fahrzeug in wenigen Minuten ganz bequem von Zuhause aus online abmelden. 

      ExplanationSlider.mt-12(class="lg:mt-24")

  section.py-24.px-6.bg-slate-200.kosten-section(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="kosten"
  )
    .container.mx-auto.grid.grid-cols-1.gap-12(class="lg:grid-cols-5")
      .col-span-1(class="lg:col-span-2")
        SectionIntro
          template(#section-name) Kosten
          template(#section-title) Alle Gebühren inklusive
          template(#section-description)
            p In unserem Service für die Kfz-Abmeldung sind die Verwaltungsgebühren der Zulassungsstelle {{ locality ? `${locality} ` : '' }}und des Kraftfahrt-Bundesamts inklusive. Sie begleichen mit Ihrer Zahlung alle notwendigen Kosten.

      .col-span-1(class="lg:col-span-3")
        PriceCard

  section.py-24.px-6.rezensionen-section(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="rezensionen"
  )
    .container.mx-auto
      .max-w-3xl
        SectionIntro
          template(#section-name) Kundenstimmen
          template(#section-title) Über 10.000 zufriedene Kunden
          template(#section-description)
            p Ihre Meinung ist uns wichtig! Unsere Kunden geben uns ständig Feedback zu unserem Service, sodass wir ihn ständig noch besser machen können.

        ReviewSlider

  section.py-24.px-3(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="online-vs-offline"
  )
    .container.mx-auto
      SectionIntro
        template(#section-name) Unterschied
        template(#section-title) Online vs. Offline
        template(#section-description)
          p Sie können Ihr Fahrzeug auf 2 verschiedenen Wegen abmelden. Der klassische Weg ist es, einen Termin bei der Zulassungsstelle {{ locality ? `${locality} ` : '' }}zu vereinbaren und dort bei einem Mitarbeiter der Behörde die Abmeldung durchzuführen.
          p.mt-4 Wir bieten eine digitale Alternative. Sie können die Abmeldung Ihres Fahrzeugs in einem Bruchteil der Zeit einfach von Zuhause aus vornehmen. So sparen Sie sich wertvolle Zeit und erhalten Ihre Bestätigung einfach digital als PDF. Die digitale Abmeldung wird an allen Stellen, z.B. bei der Versicherung, genauso anerkannt, wie die klassische Variante.

      OnlineVsOfflineTable.mt-12(
        :locality="locality"
        class="md:block"
      ).hidden

  section.py-24.px-3(
    v-intersection-observer="onIntersectionObserver"
    data-section-name="faq"
  )
    .container.mx-auto
      SectionIntro.text-center.max-w-3xl
        template(#section-name) FAQ
        template(#section-title) Häufig gestellte Fragen
        template(#section-description)
          p Falls Sie weitere Fragen oder Anregungen haben, können Sie sich in diesem Bereich über bekannte Fragestellungen informieren.

      Faq

  SeoContent(
    :locality="locality"
  )

  StickyCtaButton
</template>

<style lang="sass">

</style>
